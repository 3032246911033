import React, { ForwardedRef, ReactElement, useCallback } from 'react';
import { css } from '@emotion/react';
import { SerializedStyles } from '@emotion/react/dist/emotion-react.cjs';
import {
  ButtonAppearance,
  ButtonVariant,
  buttonVariantStyle,
} from '~/common/components/button/button-color-style';
import { assertNever } from '~/common/utils/assertNever';
import { Icon, IconColor, IconSize } from '~/common/components/icon';

type ExtractedIconSize = Extract<IconSize, 's' | 'm'>; // TODO: xs のサイズを追加する

type ButtonBaseProps = {
  id?: string;
  variant: ButtonVariant;
  appearance?: ButtonAppearance;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: 'submit' | 'button';
  iconPosition?: 'left' | 'right';
  /** @deprecated Icon のみのボタンの場合は ariaLabel を利用する */
  dataTestId?: string;
};

/** Icon のみのボタンの場合は ariaLabel 必須、children 無し、size 指定可能 */
type IconButtonProps = {
  icon: ReactElement;
  ariaLabel: string;
  iconSize?: ExtractedIconSize;
} & {
  // children は持たない
  children?: never;
};

/**
 *  label(children) がある場合は icon, ariaLabel は Optional
 *  size を指定すると型エラー
 */
type LabeledButtonProps = {
  icon?: ReactElement;
  children: string;
  ariaLabel?: string;
} & {
  // iconSize は持たない
  iconSize?: never;
};

type ButtonProps = ButtonBaseProps & (IconButtonProps | LabeledButtonProps);

const Button = React.forwardRef(function Button(
  {
    children,
    id,
    variant = 'primary',
    appearance = 'fill',
    disabled = false,
    onClick,
    type,
    icon,
    iconPosition = 'left',
    ariaLabel,
    iconSize = 'm',
    dataTestId,
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const IconElem = useCallback(() => {
    if (!icon) {
      return <></>;
    }
    return (
      <Icon color={iconColor(variant, appearance)} size={children ? 's' : iconSize}>
        {icon}
      </Icon>
    );
  }, [children, variant, appearance, icon, iconSize]);
  return (
    <button
      id={id}
      ref={ref}
      css={[
        styles.buttonBase,
        buttonVariantStyle(variant, appearance),
        buttonIconStyle(icon, children),
        !children && buttonSizeStyle(iconSize),
      ]}
      aria-label={ariaLabel}
      disabled={disabled}
      onClick={
        onClick
          ? (e) => {
              e.stopPropagation();
              onClick(e);
            }
          : undefined
      }
      type={type ? type : 'button'}
      data-testid={dataTestId}
    >
      {iconPosition === 'left' && <IconElem />}
      {children}
      {iconPosition === 'right' && <IconElem />}
    </button>
  );
});

const iconColor = (variant: ButtonVariant, appearance: ButtonAppearance): IconColor => {
  const variantAppearance = `${variant}_${appearance}` as const;
  switch (variantAppearance) {
    case 'primary_fill':
    case 'danger_fill':
      return 'white';
    case 'primary_outline':
    case 'primary_ghost':
      return 'primary';
    case 'secondary_fill':
    case 'secondary_outline':
    case 'secondary_ghost':
      return 'dark';
    case 'danger_outline':
    case 'danger_ghost':
      return 'danger';
    default:
      return assertNever(variantAppearance);
  }
};

const buttonIconStyle = (icon?: ReactElement, children?: string): SerializedStyles | undefined => {
  if (!icon) return undefined;
  // アイコン ＋ テキスト
  if (children) {
    return styles.buttonWithIcon;
  }
  // アイコンのみ
  return styles.buttonIconOnly;
};

const buttonSizeStyle = (size: ExtractedIconSize) => ('m' === size ? styles.sizeM : styles.sizeS);

const styles = {
  buttonBase: css`
    border-radius: var(--border-radius-m);
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    outline: none;
    padding: 7px var(--spacing-5);
    text-align: center;
    text-decoration: none;
    font-family: var(--font-family-jp);
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-w6);
    height: 32px;
    line-height: 16px;
    cursor: pointer;
    transition:
      background-color 300ms,
      box-shadow 300ms;
    box-shadow: transparent;
    white-space: nowrap;

    &:disabled {
      opacity: 33%;
      cursor: not-allowed;
    }
  `,
  buttonWithIcon: css`
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-2);
  `,
  buttonIconOnly: css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
  `,
  sizeS: css`
    height: 24px;
    width: 24px;
  `,
  sizeM: css`
    height: 32px;
    width: 32px;
  `,
};

export { Button };
export type { ButtonProps };
