import React, { ReactNode, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import { useLoginUser } from '~/common/providers/auth/use-login-user';
import { AppLayout } from '~/common/components/layout';
import { clearAllItemsInLocalStorage } from '~/common/utils/local-storage';
import { useEnabledFeatureMap } from '~/common/hooks/use-enabled-feature-map';
import { useTenant } from '~/common/hooks/use-tenant';

type Props = {
  children?: ReactNode;
};

const LayoutContainer: React.FC<Props> = React.memo(function LayoutContainer({ children }) {
  const { logout } = useAuth0();
  const loginUser = useLoginUser();
  const router = useRouter();
  const currentUrl = router.pathname;
  const { data: enabledFeatureMap } = useEnabledFeatureMap();
  const { data: tenant } = useTenant();

  const logoutWithClearLocalStorage = useCallback(() => {
    clearAllItemsInLocalStorage();
    logout();
  }, [logout]);

  return (
    <>
      {loginUser && enabledFeatureMap && tenant && (
        <AppLayout
          logout={logoutWithClearLocalStorage}
          loginUser={loginUser.userInfo}
          role={loginUser.role}
          currentUrl={currentUrl}
          enabledFeatureMap={enabledFeatureMap}
          tenant={tenant}
        >
          {children}
        </AppLayout>
      )}
    </>
  );
});

export { LayoutContainer };
