import useSWR, { SWRConfiguration } from 'swr';
import { ReqOptions, useAuthReqOptions } from './use-auth-req-options';
import { useHandleHttpErrorForDefaultErrorHandling } from '~/common/hooks/http-error/use-handle-http-error';
import { UseErrorHandling } from '~/common/hooks/error-handling/use-error-handling';

type UseFetchOption<T> = Partial<{
  revalidateOnMount: boolean;
  dedupingInterval: number;
  refreshInterval: number;
  onSuccess?: (data: T, key: string, config: SWRConfiguration) => void;
}>;

/**
 * 非同期によるデータの取得を行うカスタムフック
 * 対応するリソースの更新は useMutate を使う
 * @see useMutate
 */
const useFetch = <T>(
  key: string | string[] | null,
  callApiF: (reqOps: ReqOptions) => Promise<T>,
  option?: UseFetchOption<T>,
  useErrorHandling: UseErrorHandling = useHandleHttpErrorForDefaultErrorHandling,
) => {
  const { getAuthReqOptions } = useAuthReqOptions();
  const handleError = useErrorHandling();

  const result = useSWR(
    key,
    async () => {
      const reqOptions = await getAuthReqOptions();
      return await callApiF(reqOptions);
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      refreshInterval: 0,
      ...option,
    },
  );

  if (result.error) {
    handleError(result.error);
  }

  return result;
};

export { useFetch };
export type { UseFetchOption };
