import React, { ForwardedRef, ReactElement } from 'react';
import { css } from '@emotion/react';
import {
  ButtonAppearance,
  buttonVariantStyle,
} from '~/common/components/button/button-color-style';
import { assertNever } from '~/common/utils/assertNever';
import { Icon } from '~/common/components/icon';
import { IconColor } from '~/common/components/icon/Icon';

type IconButtonVariant = 'primary' | 'secondary' | 'danger';
type Size = 's' | 'm';

type IconButtonProps = {
  variant: IconButtonVariant;
  appearance: ButtonAppearance;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  ariaLabel?: string;
  icon: ReactElement;
  size?: Size;
};

/** @deprecated こちらは利用せず、Button を children 無指定で利用すること */
const IconButton = React.forwardRef(function Button(
  { variant, appearance, disabled = false, onClick, icon, ariaLabel, size = 'm' }: IconButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <button
      ref={ref}
      css={[styles.button, buttonVariantStyle(variant, appearance), buttonSizeStyle(size)]}
      disabled={disabled}
      onClick={onClick}
      type="button"
      aria-label={ariaLabel}
    >
      <Icon color={iconColor(variant, appearance)} size={size}>
        {icon}
      </Icon>
    </button>
  );
});

const iconColor = (variant: IconButtonVariant, appearance: ButtonAppearance): IconColor => {
  const variantAppearance = `${variant}_${appearance}` as const;
  switch (variantAppearance) {
    case 'primary_fill':
    case 'danger_fill':
      return 'white';
    case 'primary_outline':
    case 'primary_ghost':
      return 'primary';
    case 'danger_outline':
    case 'danger_ghost':
      return 'danger';
    case 'secondary_fill':
    case 'secondary_outline':
    case 'secondary_ghost':
      return 'dark';
    default:
      return assertNever(variantAppearance);
  }
};

const buttonSizeStyle = (size: Size) => (size === 'm' ? styles.sizeM : styles.sizeS);

const styles = {
  button: css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-m);
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    outline: none;
    text-align: center;
    text-decoration: none;
    font-family: var(--font-family-jp);
    font-weight: var(--font-weight-w6);
    line-height: 16px;
    cursor: pointer;
    transition:
      background-color 300ms,
      box-shadow 300ms;
    box-shadow: transparent;
    white-space: nowrap;

    &:disabled {
      opacity: 33%;
      cursor: not-allowed;
    }
  `,
  sizeS: css`
    height: 24px;
    width: 24px;
  `,
  sizeM: css`
    height: 32px;
    width: 32px;
  `,
};

export { IconButton };
export type { IconButtonProps };
