import React, { ReactNode, useEffect } from 'react';
import { Role } from '~/common/providers/auth/role';
import { useToast } from '~/common/components/toast/use-toast';
import { useMyRole } from '~/common/providers/auth/use-login-user';

type Props = {
  allowedRoles: Role[];
  withErrorToast?: boolean;
  children: ReactNode;
};

// TODO: RoleGuard をすべて Replace したら、RoleGuard に Rename すること
const NewRoleGuard: React.FC<Props> = React.memo(function RoleGuard({
  allowedRoles,
  children,
  withErrorToast,
}) {
  const myRole = useMyRole();
  const { addToast } = useToast();

  const permitted = myRole && allowedRoles.includes(myRole);

  useEffect(() => {
    if (withErrorToast != null && !permitted) {
      addToast({
        text: 'この機能にアクセスする権限がありません。管理者にお問い合わせください',
        type: 'error',
      });
    }
  }, [withErrorToast, addToast, permitted]);

  return <>{permitted && children}</>;
});

export { NewRoleGuard };
