import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import { Span } from '~/common/components/span';

type Props = {
  onClick: (event: React.MouseEvent) => Promise<void> | void;
  disabled?: boolean;
  selected?: boolean;
  className?: string;
  ellipsis?: boolean;
  /** 原則としてMenuItemのラベルは文字列のみ。MenuItem内をカスタマイズしたい場合はReactNodeを渡すことができます。 */
  children?: string | ReactNode;
};

const MenuItem: React.FC<Props> = React.memo(function MenuItem({
  onClick,
  disabled,
  selected,
  className,
  children,
  ellipsis,
}) {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (['Tab', 'Space'].includes(e.code)) return; // Tab, Space の場合は default の動作を行う
    e.preventDefault();
  };

  return (
    <button
      className={className}
      css={[styles.item, selected && styles.selected]}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      disabled={disabled}
    >
      {typeof children === 'string' ? (
        <Span level={2} color={disabled ? 'gray' : 'dark'} ellipsis={ellipsis}>
          {children}
        </Span>
      ) : (
        children
      )}
    </button>
  );
});

const styles = {
  item: css`
    width: 100%;
    padding: 0 var(--spacing-5);
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    min-height: 36px;
    overflow: hidden;
    color: var(--text-normal);
    background-color: transparent;

    &:hover:not([disabled]),
    &:focus {
      background-color: var(--color-neutral-3);
    }

    &:disabled {
      color: var(--text-disabled);
      cursor: not-allowed;
    }
  `,
  selected: css`
    background-color: var(--color-primary-1);
  `,
};

export { MenuItem };
