import { assertNever } from '~/common/utils/assertNever';
import { UserViewRoleEnum } from '~/common/api';

type Auth0Role =
  | 'ROLE_LOGLASS_SYSTEM_ADMIN' // ログラスシステム管理者
  | 'ROLE_TENANT_ADMIN' // 管理者
  | 'ROLE_CORP_PLANNING' // 経営企画
  | 'ROLE_MANAGER' // 編集者(旧: 事業部長)
  | 'ROLE_GENERAL' // 閲覧者(旧: 一般社員)
  | 'ROLE_IT_STAFF'; // 設定者(旧: IT担当)

const ROLES = Object.values(UserViewRoleEnum);
type Role = (typeof ROLES)[number];

const convertToRole = (role: Auth0Role): Role => {
  switch (role) {
    case 'ROLE_LOGLASS_SYSTEM_ADMIN':
      return UserViewRoleEnum.LoglassSystemAdmin;
    case 'ROLE_TENANT_ADMIN':
      return UserViewRoleEnum.TenantAdmin;
    case 'ROLE_CORP_PLANNING':
      return UserViewRoleEnum.CorpPlanning;
    case 'ROLE_MANAGER':
      return UserViewRoleEnum.Manager;
    case 'ROLE_GENERAL':
      return UserViewRoleEnum.General;
    case 'ROLE_IT_STAFF':
      return UserViewRoleEnum.ItStaff;
    default:
      return assertNever(role);
  }
};

const roleText = (role: Role): string => {
  switch (role) {
    case UserViewRoleEnum.LoglassSystemAdmin:
      return 'ログラスシステム管理者';
    case UserViewRoleEnum.TenantAdmin:
      return '管理者';
    case UserViewRoleEnum.CorpPlanning:
      return '経営企画';
    case UserViewRoleEnum.Manager:
      return '編集者';
    case UserViewRoleEnum.General:
      return '閲覧者';
    case UserViewRoleEnum.ItStaff:
      return '設定者';
    default:
      return assertNever(role);
  }
};

export { roleText, ROLES, UserViewRoleEnum as RoleEnum, convertToRole };
export type { Auth0Role, Role };
