import { useCallback } from 'react';
import { getErrorMessage, HttpError, toHttpError } from '~/common/hooks/http-error/http-error';
import { useToast } from '~/common/components/toast/use-toast';
import { COMMON_ERROR_MSG } from '~/common/hooks/http-error/http-error-message-map';
import { useSendErrorToDataDog } from '~/common/hooks/use-send-error-to-data-dog';
import { useHandleUnauthorizedError } from '~/common/hooks/http-error/use-handle-unauthorized-error';
import { UseErrorHandling } from '~/common/hooks/error-handling/use-error-handling';

type Return = {
  handleHttpError: ReturnType<UseErrorHandling>;
};

const useHandleHttpError = (): Return => {
  const { addToast } = useToast();
  const { sendErrorToDataDog } = useSendErrorToDataDog();
  const { handleUnauthorizedError } = useHandleUnauthorizedError();

  const handleHttpError = useCallback(
    async (e: unknown | undefined): Promise<void> => {
      if (e == null) {
        return;
      }

      if (isIgnoredError(e as Error)) {
        addToast({
          type: 'error',
          text: COMMON_ERROR_MSG,
        });
        return;
      }

      await sendErrorToDataDog(e as Error);

      const httpError = await toHttpError(e as Error);

      const errorMessage = httpError && getErrorMessage(httpError);
      if (errorMessage) {
        addToast({ type: 'error', text: errorMessage });
      }

      handleUnauthorizedError(httpError);
    },
    [addToast, handleUnauthorizedError, sendErrorToDataDog],
  );

  return { handleHttpError };
};

const isIgnoredError = (error: Error) => {
  return error.message === 'Network Error';
};

/**
 * useFetch 用の handleHttpError を取得する
 * useFetch は共通化した UseErrorHandling 型を使うため、Property から関数を返す必要がある
 *
 * TODO: useHandleHttpError を UseErrorHandling 型にしてこの関数を削除する
 */
const useHandleHttpErrorForDefaultErrorHandling: UseErrorHandling = () => {
  const { handleHttpError } = useHandleHttpError();
  return handleHttpError;
};

export type { HttpError };
export { useHandleHttpError, isIgnoredError, useHandleHttpErrorForDefaultErrorHandling };
