import React, { ForwardedRef, PropsWithChildren, ReactElement } from 'react';
import { css } from '@emotion/react';
import { assertNever } from '~/common/utils/assertNever';

type SpanProps = {
  level?: 1 | 2 | 3 | 4;
  lang?: 'jp' | 'en';
  color?: SpanColor;
  bold?: boolean;
  prewrap?: boolean;
  ellipsis?: boolean;
  className?: string;
  dataTestId?: string;
};

type SpanColor =
  | 'dark'
  | 'darkGray'
  | 'gray'
  | 'lightGray'
  | 'danger'
  | 'highlight'
  | 'white'
  | 'primary'
  | 'success'
  | 'warn'
  | 'info'
  | 'inherit';

const Span = React.forwardRef(function Span(
  {
    children,
    level = 3,
    lang = 'jp',
    color = 'dark',
    bold,
    prewrap,
    ellipsis,
    className,
    dataTestId,
  }: PropsWithChildren<SpanProps>,
  ref: ForwardedRef<HTMLSpanElement>,
): ReactElement {
  return (
    <span
      ref={ref}
      className={className}
      css={css([
        styles.text,
        bold && styles.bold,
        levelStyle(level),
        langStyle(lang),
        colorStyle(color),
        prewrap && styles.prewrap,
        ellipsis && styles.ellipsis,
      ])}
      data-testid={dataTestId}
    >
      {children}
    </span>
  );
});

const langStyle = (lang: 'jp' | 'en') => {
  switch (lang) {
    case 'jp':
      return styles.langJp;
    case 'en':
      return styles.langEn;
    default:
      return assertNever(lang);
  }
};

const levelStyle = (level: 1 | 2 | 3 | 4) => {
  switch (level) {
    case 1:
      return styles.level1;
    case 2:
      return styles.level2;
    case 3:
      return styles.level3;
    case 4:
      return styles.level4;
    default:
      return assertNever(level);
  }
};

const colorStyle = (color: SpanColor) => {
  switch (color) {
    case 'dark':
      return styles.colorDark;
    case 'darkGray':
      return styles.colorDarkGray;
    case 'gray':
      return styles.colorGray;
    case 'lightGray':
      return styles.colorLightGray;
    case 'danger':
      return styles.colorDanger;
    case 'white':
      return styles.colorWhite;
    case 'primary':
      return styles.colorPrimary;
    case 'success':
      return styles.colorSuccess;
    case 'warn':
      return styles.colorWarn;
    case 'highlight':
      return styles.colorHighlight;
    case 'info':
      return styles.colorInfo;
    case 'inherit':
      return styles.colorInherit;
    default:
      return assertNever(color);
  }
};

const styles = {
  text: css`
    font-weight: var(--font-weight-w4);
    letter-spacing: normal;
    text-transform: none;
    word-break: break-all;
  `,
  bold: css`
    font-weight: var(--font-weight-w6);
  `,
  langJp: css`
    font-family: var(--font-family-jp);
  `,
  langEn: css`
    font-family: var(--font-family-en);
  `,
  level1: css`
    line-height: 24px;
    font-size: var(--font-size-m);
  `,
  level2: css`
    line-height: 20px;
    font-size: var(--font-size-s);
  `,
  level3: css`
    line-height: 18px;
    font-size: var(--font-size-xs);
  `,
  level4: css`
    line-height: 10px;
    font-size: var(--font-size-xxs);
  `,
  colorDark: css`
    color: var(--color-text);
  `,
  colorDarkGray: css`
    color: var(--color-neutral-7);
  `,
  colorGray: css`
    color: var(--color-neutral-6);
  `,
  colorLightGray: css`
    color: var(--color-neutral-4);
  `,
  colorDanger: css`
    color: var(--color-danger);
  `,
  colorWhite: css`
    color: var(--color-neutral-1);
  `,
  colorPrimary: css`
    color: var(--color-primary-6);
  `,
  colorSuccess: css`
    color: var(--color-success-6);
  `,
  colorWarn: css`
    color: var(--color-warning-6);
  `,
  colorHighlight: css`
    color: var(--color-highlight-text);
  `,
  colorInfo: css`
    color: var(--color-info-6);
  `,
  colorInherit: css`
    color: inherit;
  `,
  prewrap: css`
    white-space: pre-wrap;
  `,
  ellipsis: css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `,
};

export { Span };
export type { SpanProps, SpanColor };
