import React, { ReactNode, useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { env } from '~/app/environment';
import { useLoginUser } from '~/common/providers/auth/use-login-user';

type DatadogRumProviderProps = {
  children?: ReactNode;
};

const DatadogRumProvider = React.memo(function DatadogRumProvider({
  children,
}: DatadogRumProviderProps): JSX.Element {
  const loginUser = useLoginUser();
  const environment = env();

  useEffect(() => {
    if ((environment?.production || environment?.staging) && loginUser) {
      datadogRum.setUser({
        id: loginUser.userInfo.userId,
        role: loginUser.role,
        tenantId: loginUser.tenantInfo.tenantId,
      });
    }
  }, [environment, loginUser]);

  return <>{children}</>;
});

export { DatadogRumProvider };
